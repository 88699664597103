/**
 * Imports and Bootstrap overrides
 */

@import "bootstrap/bootstrap";

@font-family-sans-serif: 'Open Sans', 'Helvetica', Arial, sans-serif;
@text-color: black;


/**
 * Colors
 */
@green: #81C041;
@orange: #FF9E16;
@gray: #7F7F7F;
@gray_banner: #3A3A3A;


/**
 * Basics
 */
.btn-primary{
	.button-variant(white; black; black);
	border-radius: 0;
}
.container{
	max-width: 970px;
}
label.error{
  font-weight: normal;
  color: red;
  margin-top: 3px;
}

/**
 * Header
 */
.header{
	//background-color: black;
	.logo{
		height: 100px;
		margin: 0;
		text-indent: -9999px;
		background-image: url('/img/b2blogo.jpg');
		background-repeat: no-repeat;
		background-size: contain;
		@media(max-width: @screen-xs-max){
			background-position: center center;
			margin: 20px 0;
		}
	}
	.social{
		padding-top: 25px;
		padding-left: 0;
		text-align: right;
		li{
			display: inline-block;
			a{
				width: 36px;
				height: 36px;
				display: block;
				background-color: @gray;
				border-radius: 18px;
				color: white;
				text-align: center;
				line-height: 36px;
				font-size: 20px;
				margin-left: 5px;
				&:hover{
					background-color: darken(@gray, 15%);
				}
			}
		}
		@media(max-width: @screen-xs-max){
			padding-top: 5px;
			text-align: center;
			margin-bottom: 20px;
		}
	}
	.partner-link{
		display: block;
		text-indent: -9999px;
		width: 315px;
		height: 75px;
		margin-top: 7px;
		float: right;
		background-image: url('/img/partner-fca.png');
		background-size: contain;
		background-repeat: no-repeat;
		&:hover{
			background-image: url('/img/partner-fca-hover.png');
		}
		@media(max-width: @screen-xs-max){
			margin: 0 auto;
			float: none;
		}
	}
}

.cust-serv{
	text-align: right;
	font-size: 16px;
	padding-top: 44px;
	font-weight: bold;
}

.promo-corner{
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 120px;
	background-image: url('/img/huddles_corner.png');
	background-size: contain;
	background-repeat: no-repeat;
	text-indent: -9999px;
	@media(max-width: 1175px){
  	display: none;
	}
}

/**
 * Home Page Promo Banner
 */
.banner{
	background-color: @gray_banner;
	//padding: 40px 0;
	margin-bottom: 30px;
	text-align: center;
	text-transform: uppercase;
	img{
		max-width: 100%;
	}
	.subtext{
		color: @orange;
		font-size: 36px;
		font-weight: 300;
		@media(max-width: @screen-xs-max){
			font-size: 20px;
		}
	}
	.main{
		color: white;
		font-size: 110px;
		font-weight: 700;
		@media(max-width: @screen-xs-max){
			font-size: 60px;
		}
		@media(max-width: 450px){
			font-size: 40px;
		}
	}
}


/**
 * Home Page Product Listings and Checkout Step
 */
.product-listing-section{

	.heading{
		margin-bottom: 20px;
		h3{
			margin-top: 12px;
			margin-bottom: 12px;
			font-size: 20px;
			span{
				font-size: 16px;
			}
		}
		&:hover{
			cursor: pointer;
		}
	}

	.checkout-heading{
		margin-bottom: 20px;
		h3{
			margin-top: 12px;
			margin-bottom: 12px;
			font-size: 20px;
			span{
				font-size: 16px;
			}
		}
	}

	.heading-box{
		border: 1px solid #707070;
		margin-top: 10px;
		margin-bottom: 0;
		background: linear-gradient(white, #D0D0D0);
	}

	.product-listing-item{

		margin-bottom: 20px;
		margin-top: 20px;

		img{
			max-width: 100%;
			height: auto;
		}

		.info{
			margin-top: 5px;
			font-size: 14px;
			text-align: center;
			font-weight: 600;
		}

		.sponsor-back{
			margin-top: 5px;
			margin-bottom: 5px;
		}

		.garments{
			margin-top: 5px;
			margin-bottom: 5px;
		}

		.custom-text{
			margin-top: 5px;
			margin-bottom: 5px;
		}

		.error{
  		color: red;
		}

		// OVERLAY STUFf
		.image-link{
			position: relative;
		}
		.overlay{
			display: block;
			position: absolute;
			width: 100%;
			padding-bottom: 10%;
			background-size: contain;
			background-repeat: no-repeat;
		}
		.section-3 .overlay{
			background-image: url('/img/customize.png');
		}
		.section-3.performance .overlay{
			background-image: url('/img/performance.png');
		}

	}

	.nav{
		margin-top: 20px;
	}

}

.checkout{
	margin-top: 10px;
	margin-bottom: 40px;
	text-align: center;
	.btn-checkout{
		width: 250px;
		max-width: 100%;
	}
	h6{
		font-weight: 600;
	}
}

.product-popup{
	width: 780px;
	min-height: 440px;
	.image{
		img{
			max-width: 100%;
			height: auto;
		}
	}
}

.sizes{

	.size{
		float: left;
		padding: 10px;
		text-align: center;
		input{
			width: 100%;
			max-width: 100px;
			padding: 3px 6px;
		}
	}

	&.size-1 .size{width: 100%;}
	&.size-2 .size{width: 50%;}
	&.size-3 .size{width: 33%;}
	&.size-4 .size{width: 25%; padding: 5px;}
	&.size-5 .size{width: 20%; padding: 5px;}
	&.size-6 .size{width: 16.6%; padding: 5px;}
	&.size-7 .size{width: 25%; padding: 5px;}
	&.size-8 .size{width: 25%; padding: 5px;}
	&.size-9 .size{width: 20%; padding: 5px;}
	&.size-10 .size{width: 20%; padding: 5px;}
	&.size-11 .size{width: 16.6%; padding: 5px;}
	&.size-12 .size{width: 16.6%; padding: 5px;}
	&.size-13 .size{width: 20%; padding: 5px;}
	&.size-14 .size{width: 20%; padding: 5px;}
	&.size-15 .size{width: 20%; padding: 5px;}

	&.quantity{
		margin-top: 25px;
		margin-bottom: 5px;
		label{
			font-weight: normal;
			line-height: 27px;
		}
		input{
			width: 50%;
			float: right;
		}
	}

}

.colors{
	text-align: center;
}

.color-picker-item{
	display: inline-block;
	margin: 0 1px;
	vertical-align: bottom;
	.color-label{
		text-indent: -9999px;
		display: block;
		width: 28px;
		height: 28px;
		background-color: white;
		margin-top: 2px;
		&.active{
			border: 2px solid black;
			margin-top: 0;
		}
		&:hover{
			cursor: pointer;
		}
	}
	input{
		display: none;
	}
}

// questions button
.have-questions{
  display: block;
  height: 200px;
  width: 322px;
  background: url('/img/questions.png');
  position: fixed;
  bottom: 0;
  right: -282px;
  transition: right .5s;
  &:hover{
    right: 0;
    transition: right .5s;
  }
}

/**
 * Checkout
 */
.checkout-table{

	.no-border{
		td{
			border: none;
		}
	}

	.product-name{
		font-weight: bold;
		vertical-align: middle;
	}

	td.price{
		text-align: right;
		padding-right: 20px;
	}

	.size, .quantity{
		text-align: center;
	}

	//border-bottom: 1px solid #dddddd;
}

.checkout-actions{
  padding-bottom: 30px;
}

.checkout-promo img{
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}


 /**
 * Admin
 */
.heading{
	h3{
		margin-top: 5px;
		margin-bottom: 15px;
	}
}
.actions{
	text-align: right;
	margin-bottom: 15px;
}
